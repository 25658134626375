<template>
  <v-card @click="$router.push(`/analysis/${game.id}`)" class="mt-6">
    <v-row>
      <v-col cols="2">
          <v-card-subtitle class="pl-8">{{ prettyDate }}</v-card-subtitle>
      </v-col>
      <v-col cols="8">
        <v-row>
          <v-img height="70" width="70" contain :src="game.home_team_logo_url"></v-img>
          <v-card-title>{{game.home_team_name}}</v-card-title>
          <v-card-title>{{game.score_home}}</v-card-title>
          <v-card-title>-</v-card-title>
          <v-card-title>{{game.score_away}}</v-card-title>
          <v-card-title>{{game.away_team_name}}</v-card-title>
          <v-img height="70" width="70" contain :src="game.away_team_logo_url"></v-img>
        </v-row>
      </v-col>
      <v-col cols="2" align="right" class="pr-8">
        <v-chip @click.stop="" small :color="game.analyzed ? 'primary' : ''">
          Analyzed
        </v-chip>
        <div class="mt-1">
          <v-chip @click.stop="$emit('edit')" small><v-icon size="12">mdi-pencil</v-icon></v-chip>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: ['game'],
  computed: {
    prettyDate() {
      const date = new Date(this.game.starttime_unix)
      return date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
    }
  }
}
</script>