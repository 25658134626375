<template>
  <v-dialog persistent max-width="1000" min-height="1000" v-model="show">
    <v-card>
      <v-card-title>
        Add game
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-row>
            <v-col cols="6">
              <v-select item-value="id" item-text="team_name" :items="league ? league.teams : []" v-model="value.home_team_id" label="Home"></v-select>
            </v-col>
            <v-col cols="6">
              <v-select item-value="id" item-text="team_name" :items="league ? league.teams : []" v-model="value.away_team_id" label="Away"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex" align-self="center" cols="6">
              <v-text-field v-model="value.score_home" label="Score home"></v-text-field>
            </v-col>
            <v-col class="d-flex" align-self="center" cols="6">
              <v-text-field v-model="value.score_away" label="Score away"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="3"
            >
              <v-switch :input-value="true" v-model="value.will_be_analyzed" label="Will be analyzed"></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-card-subtitle>Game starttime</v-card-subtitle>
              <VueCtkDateTimePicker v-model="value.starttime_unix" @input="$emit('input', {...value, publishtime_unix: ( Number($event) + twoHoursInMillis ).toString() })" format="YYYY-MM-DD HH:mm" output-format="x"></VueCtkDateTimePicker>
            </v-col>
            <v-col cols="6">
              <v-card-subtitle>Game published</v-card-subtitle>
              <VueCtkDateTimePicker v-model="value.publishtime_unix" format="YYYY-MM-DD HH:mm" output-format="x"></VueCtkDateTimePicker>
            </v-col>
          </v-row>
          <v-select class="mt-4" label="video type" :items="['hls', 'mp4']" v-model="value.video_type"></v-select>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="video url"
                v-model="value.video_url"
              >

              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <v-btn color="red" class="mb-10" @click="deletingGame = true">
          DELETE
        </v-btn>
      </v-card-text>
      <confirmation-dialog
        :show="deletingGame"
        subText="Oletko aivan aivan aivan varma että haluat poistaa tämän pelin? Kaikki työ menee sit siinä."
        text="Niin poistetaanko?"
        btnColor="red"
        btnText="joo joo pää kii"
        @decline="deletingGame = false"
        @accept="deleteThisGame()"
      >
      </confirmation-dialog>
      <v-card-actions>
        <v-btn @click="$emit('close')">
          Kumoa
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn 
          color="primary"
          @click="$emit('save')"
        >
          Tallenna
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ConfirmationDialog from '../../../ConfirmationDialog.vue'
import { mapActions } from 'vuex'

export default {
  components: { ConfirmationDialog },
  props: ['show', 'value', 'league'],
  data: () => ({
    twoHoursInMillis: 1000 * 60 * 60 * 2,
    deletingGame: false
  }),
  methods: {
    ...mapActions('admin', [
      'deleteGame'
    ]),
    ...mapActions('noti', [
      'info',
      'error'
    ]),
    deleteThisGame() {
      this.deleteGame(this.value.id)
        .then(() => {
          this.info('Peli poistettu')
          this.$emit('close')
        })
        .catch(e => {
          this.error(e)
        })
    }
  }
}
</script>